<template>
    <section class="about" id="aboutme">
        <h2>About me</h2>
        <p v-for="(paragraph, index) in paragraphs" :key="index">
            {{ paragraph }}
        </p>
    </section>
</template>

<script>
    export default {
        setup() {
            const timeDeveloping = new Date().getFullYear() - 2014;
            const timeWithVue = new Date().getFullYear() - 2018;
            const paragraphs = [
                `I'm self taught and extremely passionate about the web and front end development. I started learning development ${timeDeveloping} years ago with responsive HTML emails, then started building banners, and then moved on to building landing pages and the front end of websites.`,
                `After that I started developing hybrid-mobile apps with Vue, creating a boilerplate with Vue, VueX, and Vue Router for those projects, and forked and rewrote a node package to build those projects for a platform called Veeva. In total I've spent ${timeWithVue} years working with Vue and built this website in Vue 3 with the Composition API.`
            ];

            return { paragraphs }
        }
    }
</script>
